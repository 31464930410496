@use '@/styles/utils/mixins.scss' as *;

.thankYouWrapper {
  background-color: $white;
  padding: 3.75rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  border-radius: 1.25rem;
  border: 0.063rem solid $primaryAlt;
  width: 100%;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);

  @include tab() {
    padding: 3.75rem 1.875rem;
    gap: 3.75rem;
  }
  .thankYouContent {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
    max-width: 51rem;
    text-align: center;
  }
  .thankYouImage {
    max-width: 10rem;
    height: auto;
    @include tab() {
      max-width: 6.25rem;
    }
  }
}
